import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import { t } from 'i18next';
import React, {
    useEffect,
    useState,
} from 'react';
import {
    connect,
    useSelector,
} from 'react-redux';

import { WithVisibility } from '../../components/WithVisibility';
import type { AppStore } from '../../state-management/store';
import ProjectListLegacy from './ProjectListLegacy';
import ProjectListRBAC from './ProjectListRBAC';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%',
});

const StyledAlertBar = styled(PortalAlertBar)({ width: '100%' });

const LearnMoreAnchorText = styled('a')(({ theme }) => ({
    fontWeight: Tokens.FontFamily.FontWeightBold,
    color: theme.palette.primary.main,
}));

const ProjectList: React.FC<{ isTenantMigrated: boolean }> = ({ isTenantMigrated }) => {
    const [ isAlertBarVisible, setIsAlertBarVisible ] = useState(false);

    const {
        accountId, tenantId,
    } = useSelector((state: AppStore) => state.authZ);

    const getAlertBarStatePreferenceKey = () => {
        if (accountId && tenantId) {
            return `ui.aic.${accountId}.${tenantId}.deprecation-warning-visible`;
        }
        return null;
    };

    const initializeAlertBarVisibility = () => {
        const preferenceKey = getAlertBarStatePreferenceKey();
        if (!preferenceKey) {
            return;
        }
        const isVisibleStorageValue = localStorage.getItem(preferenceKey);
        const isVisible = !(isVisibleStorageValue === 'false');
        setIsAlertBarVisible(isVisible);
    };

    useEffect(() => {
        initializeAlertBarVisibility();
    }, [ accountId, tenantId ]);

    const saveAlertBarClosedState = () => {
        setIsAlertBarVisible(false);
        const preferenceKey = getAlertBarStatePreferenceKey();
        if (!preferenceKey) {
            return;
        }
        localStorage.setItem(preferenceKey, 'false');
    };

    return (
        <StyledContainer>
            <WithVisibility visible={isAlertBarVisible}>
                <StyledAlertBar
                    data-testid="deprecation-warning-banner"
                    status='warning'
                    onAlertDismissed={saveAlertBarClosedState}>
                    {t('home_deprecation_warning_label')}
                &nbsp;
                    <LearnMoreAnchorText
                        rel="noopener noreferrer"
                        href="https://docs.uipath.com/ai-center/automation-cloud/latest/user-guide/december-2024"
                        target="_blank"
                    >
                        {t('home_deprecation_warning_learn_more')}
                    </LearnMoreAnchorText>
                </StyledAlertBar>
            </WithVisibility>
            <WithVisibility visible={isTenantMigrated === true} >
                <ProjectListRBAC />
            </WithVisibility>
            <WithVisibility visible={isTenantMigrated === false} >
                <ProjectListLegacy />
            </WithVisibility>
        </StyledContainer>
    );
};

export default connect((state: any) => ({ isTenantMigrated: state.config.isTenantMigrated }))(ProjectList);
