import LinkIcon from '@mui/icons-material/Link';
import {
    IconButton,
    Link,
    styled,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import { ApShell } from '@uipath/portal-shell-react';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ContextMenu } from '../../components/Menu';
import { WithVisibility } from '../../components/WithVisibility';
import { Configuration } from '../../enums/Configuration';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import {
    getAccountAndTenantFromCannonicalPath,
    isCannonicalPath,
} from '../../route/routeHelper';
import type { PortalShellV3Props } from './App.d';
import AppContent from './AppContent';
import ToastNotificationProvider from './ToastNotificationProvider';
import { UpdateAccessControlDialog } from './UpdateAccessControlDialog';

const SwaggerLinkContainer = styled('div')(({ theme }) => ({ marginTop: theme.spacing(0.5) }));

const SwaggerLink = styled(Link)(({ theme }) => ({
    color: theme.palette.semantic.colorIconDefault,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    '&:hover': { textDecoration: 'none' },
    '&:visited': { color: theme.palette.semantic.colorIconDefault },
    '&:focus-visible': { outline: `solid 2px ${theme.palette.semantic.colorFocusIndicator}` },
}));

const SwaggerLinkIcon = styled(LinkIcon)(({ theme }) => ({
    color: theme.palette.semantic.colorForegroundHigh,
    marginRight: theme.spacing(1),
}));

const LicenseUsageButton = styled('div')({
    marginLeft: '15px',
    marginRight: '15px',

    '& .MuiSvgIcon-root': { fontSize: Tokens.FontFamily.FontHeader3Size },
});

const HeaderEndContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const AppShellContainer = styled('div')({
    height: '100vh',
    width: '100%',
    overflowX: 'auto',
});

const AppShellContent = styled('div')({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative',
    overflowX: 'auto',
});

const PortalShellV3: React.FC<PortalShellV3Props> = ({
    loggedIn,
    isErrored,
    isOnPrem,
    portalNavEnabled,
    logoutCallback,
    onTenantChanged,
    themeChangeCallback,
    configState,
    items,
    updateLang,
    langChangeCallback,
    link,
    handleClick,
    showUpdateToNewAccessControl,
    isUpdateToNewAccessControlDialogOpen,
    closeUpdateToNewAccessControlDialog,
    updateTenantToNewAccessControl,
    anchorEl,
    handleClose,
    authConfig,
    isFirstParty,
    isOnStandalone,
}) => {
    const { t } = useTranslation();
    const [ homeUrl, setHomeUrl ] = useState<string>('');

    const { state } = useContext(ProjectsContext);
    const projectName = React.useMemo(() => state.currentProject?.name, [ state ]);

    React.useEffect(() => {
        document.addEventListener('languageChanged', langChangeCallback);
        document.addEventListener('logout', logoutCallback);
        document.addEventListener('tenantChanged', onTenantChanged);
        document.addEventListener('themeChanged', themeChangeCallback);
    }, [ isOnPrem, updateLang ]);

    React.useEffect(() => {
        if (configState === Configuration.APPREADY && portalNavEnabled !== undefined) {
            // event handling for portal-app-bar component
            document.addEventListener('tenantChanged', onTenantChanged);
        }
    }, [ isOnPrem, configState, portalNavEnabled ]);

    React.useEffect(() => {
        const {
            account, tenant,
        } = getAccountAndTenantFromCannonicalPath(!isFirstParty);
        let homeUrlTemp = `${authConfig?.config?.redirectUrl?.replace(':account', account)?.replace(':tenant', tenant)}`;
        homeUrlTemp = homeUrlTemp?.substring(0, homeUrlTemp.lastIndexOf('/'));
        setHomeUrl(homeUrlTemp);
    }, [ authConfig ]);

    return (
        <AppShellContainer>
            <ApShell
                product-name={`${t('portal_nav_aifabric_title')}`}
                feature={projectName || ''}
                focus-mode={!portalNavEnabled}
                logo-click-href={`${homeUrl}`}
                skip-to-target="#page-title"
                showPreferences={!isOnStandalone}
                style={{
                    width: '100%',
                    display: 'block',
                }}
            >
                <HeaderEndContainer
                    slot="slot-end">
                    {isCannonicalPath() && (
                        <SwaggerLinkContainer>
                            <SwaggerLink
                                href={link}
                                target="_blank">
                                <SwaggerLinkIcon />
                                <div>
                                    {t('api_documentation')}
                                </div>
                            </SwaggerLink>
                        </SwaggerLinkContainer>
                    )}
                    {loggedIn ? (
                        <LicenseUsageButton>
                            <IconButton
                                aria-label={t('a11y_app_options')}
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <i className="pt-material-icons-outlined material-icons-outlined">
                                    more_vert
                                </i>
                            </IconButton>
                        </LicenseUsageButton>
                    ) : null}
                </HeaderEndContainer>
                <AppShellContent>
                    <ToastNotificationProvider>
                        <WithVisibility visible={configState === Configuration.APPREADY && portalNavEnabled !== undefined}>
                            <UpdateAccessControlDialog
                                showUpdateToNewAccessControl={showUpdateToNewAccessControl}
                                isUpdateToNewAccessControlDialogOpen={isUpdateToNewAccessControlDialogOpen}
                                closeUpdateToNewAccessControlDialog={closeUpdateToNewAccessControlDialog}
                                updateTenantToNewAccessControl={updateTenantToNewAccessControl}
                            />
                        </WithVisibility>
                        <ContextMenu
                            id="license-menu"
                            style={{ marginLeft: '10px' }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            keepMounted
                            anchorReference="anchorEl"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose}
                            items={items}
                        />
                        <AppContent
                            loggedIn={loggedIn}
                            isErrored={isErrored} />
                    </ToastNotificationProvider>
                </AppShellContent>
            </ApShell>
        </AppShellContainer>
    );
};

export default connect((state: any) => ({
    isOnStandalone: state.config.paths ? state.config.paths.isOnStandalone : false,
    isHostAdmin: !!state.auth.isHostAdmin,
    configState: state.config.state,
    portalNavEnabled: !!state.config.paths?.portalNavEnabled,
    authConfig: state.config.paths ? state.config.paths.authConfig : {},
    isFirstParty: !!state.config.paths.isFirstParty,
}))(PortalShellV3);
