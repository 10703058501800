import AddIcon from '@mui/icons-material/Add';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
    Button,
    styled,
    Typography,
} from '@mui/material';
import type {
    PageListingDtoOfProjectDto,
    ProjectDto,
} from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import type { ReactNode } from 'react';
import React, {
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import {
    deleteProject,
    getProjects,
} from '../../api/client/projectManagerClient';
import { useFeedback } from '../../api/global/useFeedback';
import type { ContextMenuItem } from '../../components/ContextPopup';
import { CustomDialog } from '../../components/Dialog';
import FullPageLoader from '../../components/FullPageLoader';
import { InfiniteScrollComponent } from '../../components/InfinitScroll';
import ProjectCard from '../../components/ProjectCard';
import { WithVisibility } from '../../components/WithVisibility';
import {
    INFINITE_SCROLLER_PAGE_NUM,
    INFINITE_SCROLLER_PAGE_SIZE,
} from '../../constants/AiappConstants';
import { AppPermissions } from '../../enums/Authorization';
import {
    Origin,
    Scope,
    Service,
} from '../../enums/ClientErrorStrings';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import {
    ProjectsContext,
    UpdateType,
} from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import { AppMetaActions } from '../../state-management/Actions';
import { store } from '../../state-management/store';
import {
    extractErrorMessage,
    getDisplayErrorCode,
} from '../../utils/CommonUtils';
import logger from '../../utils/Logging';
import landingImage from './defaultImage.png';

const ProjectListContainer = styled('div')(({ theme }) => ({
    display: 'block',
    padding: '24px 24px 0 24px',
    width: 'auto',
    height: 'calc(100vh - 82px)',
    overflowY: 'auto',

    '& .portal-landing': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexGrow: 1,
        backgroundColor: theme.palette.semantic.colorBackground,
        padding: '24px',

        '& .text-area': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },

        '& .image-spacing': {
            display: 'flex',
            minWidth: '48px',
            minHeight: '48px',
        },
        '& .image': {
            width: '100vh',
            height: '70vh',
        },
        '& .footer-slots': {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '48px',
        },
        '& .middle-slots': {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '48px',
            paddingRight: '24px',
        },
        '& .sub-title': {
            display: 'flex',
            flexDirection: 'row',
            fontSize: Tokens.FontFamily.FontLSize,
            lineHeight: Tokens.FontFamily.FontLLineHeight,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        '& .title': {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '24px',
            fontSize: Tokens.FontFamily.FontHeader3Size,
            lineHeight: Tokens.FontFamily.FontHeader3LineHeight,
            color: theme.palette.semantic.colorForegroundEmp,
            fontWeight: Tokens.FontFamily.FontWeightBold,
        },
    },
}));

const HeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    margin: theme.spacing(1),
}));

const ProjectsLabel = styled('h2')(({ theme }) => ({
    padding: '0px',
    margin: '0px',
    color: theme.palette.semantic.colorForegroundEmp,
    width: '50%',
}));

const LandingButton = styled(Button)({ width: '280px' });

const CreateProjectButton = styled(Button)(({ theme }) => ({
    padding: '0',
    margin: '0',
    marginRight: theme.spacing(6),
    fontFamily: Tokens.FontFamily.FontTitle,
    fontStyle: 'normal',
    fontWeight: Tokens.FontFamily.FontWeightBold,
    alignItems: 'right',
    fontSize: Tokens.FontFamily.FontMSize,
    lineHeight: Tokens.FontFamily.FontMLineHeight,
    color: theme.palette.semantic.colorForegroundHigh,
}));

const CreateProjectButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px',
    margin: '0px',
});

const ProjectList: React.FC = () => {

    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.tenantData.permissionSet;
    const feedback = useFeedback();
    const [ projects, setProjects ] = useState<ProjectDto[] | undefined>([]);
    const [ projectData, setProjectData ] = useState<PageListingDtoOfProjectDto | undefined>();
    const [ isProjectsLoaded, setIsProjestsLoaded ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const portalAlertBarElementRef = useRef<HTMLPortalAlertBarElement>(null);

    const updateProjectsList = (projectList: ProjectDto[] | undefined): void => {
        setProjects(projectList);
    };

    const [ rbacBannerVisible, setRbacBannerVisible ] = useState(true);

    const clearRbacMigrationBannerFlagFromStorage = (): void => {
        setRbacBannerVisible(false);
    };

    const projectListFetchError = (error: any) => {
        logger.error({
            identifier: 'Project List',
            message: 'Error while getting projects',
            error,
        });
        setIsError(true);
        setIsProjestsLoaded(false);
        if (error?.response?.status === 403) {
            store.dispatch({
                type: AppMetaActions.FAIL,
                payload: {
                    status: 'empty_permissions_project',
                    backendCode: getDisplayErrorCode(Scope.Core, Service.HELPER, Origin.PROJECTLIST, error, error.response.status),
                },
            });
        } else {
            feedback.enqueueError(extractErrorMessage(
                error,
                t('feedback_project_list_failed'),
                { 20205: {} },
            ));
        }
    };

    const fetchMoreData = async () => {
        const projectDataPageNum: any = projectData?.pageNum;
        getProjects(projectDataPageNum + 1, INFINITE_SCROLLER_PAGE_SIZE).then((projectsData) => {
            if (projectsData?.dataList && projectsData?.dataList.length > 0) {
                setProjectData(projectsData);
                setProjects(projects?.concat(projectsData?.dataList));
            }
            return true;
        })
            .catch((error) => {
                projectListFetchError(error);
            });
    };

    React.useEffect(() => {
        if (rbacBannerVisible) {
            portalAlertBarElementRef.current?.addEventListener('alertDismissed', clearRbacMigrationBannerFlagFromStorage);
        }
        return () => portalAlertBarElementRef.current?.removeEventListener('alertDismissed', clearRbacMigrationBannerFlagFromStorage);
    }, [ portalAlertBarElementRef ]);

    React.useEffect(() => {
        getProjects(INFINITE_SCROLLER_PAGE_NUM, INFINITE_SCROLLER_PAGE_SIZE).then((projectsData) => {
            setProjectData(projectsData);
            setProjects(projectsData?.dataList);
            setIsError(false);
            setIsProjestsLoaded(true);
            return true;
        })
            .catch((error) => {
                projectListFetchError(error);
            });
    }, []);

    const { t } = useTranslation();

    /* Div id is being used */
    return <ProjectListContainer
        id="scrollableDiv"
        data-testid="projectInfiniteScroller">
        <div style={{ marginBottom: '15px' }}>
            <WithVisibility visible={rbacBannerVisible}>
                <PortalAlertBar
                    ref={portalAlertBarElementRef}
                    cancelable
                    status='info'>
                    <Typography>
                        {t('rbac_migration_alert')}
                    </Typography>
                    &nbsp;
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://forum.uipath.com/t/updating-to-the-new-ai-center-access-control/458016">
                        {t('rbac_migration_alert_link')}
                    </a>
                </PortalAlertBar>
            </WithVisibility>
        </div>
        {(permissions.indexOf(AppPermissions.MLProjects_View) > -1) ? (

            /* Add InfiniteScrollComponent only when project list is available to avoid multiple loaders */
            projects?.length ?
                <InfiniteScrollComponent
                    items={projects}
                    next={fetchMoreData}
                    hasMore={projectData?.pageNum && projectData?.pageSize && projectData?.totalCount
                        && (projectData?.pageNum * projectData?.pageSize < projectData?.totalCount) ? true : false}
                    hasScrolled={projectData?.pageNum && projectData?.pageNum >= 2 ? true : false}
                    scrollableTarget="scrollableDiv"
                >
                    <ProjectListView
                        projects={projects}
                        canDelete={(permissions.indexOf(AppPermissions.MLProjects_Delete) > -1)}
                        canEdit={(permissions.indexOf(AppPermissions.MLProjects_Edit) > -1)}
                        canCreate={(permissions.indexOf(AppPermissions.MLProjects_Create) > -1)}
                        isProjectsLoaded={isProjectsLoaded}
                        updateProjectsList={updateProjectsList}
                        isError={isError}
                    />
                </InfiniteScrollComponent>
                :

                <ProjectListView
                    projects={projects}
                    canDelete={(permissions.indexOf(AppPermissions.MLProjects_Delete) > -1)}
                    canEdit={(permissions.indexOf(AppPermissions.MLProjects_Edit) > -1)}
                    canCreate={(permissions.indexOf(AppPermissions.MLProjects_Create) > -1)}
                    isProjectsLoaded={isProjectsLoaded}
                    updateProjectsList={updateProjectsList}
                    isError={isError}
                />

        ) : <h2>
            {t('projects')}
        </h2>}
    </ProjectListContainer>
    ;
};

interface ProjectListViewProps {
    projects: ProjectDto[] | undefined;
    canDelete: boolean;
    canEdit: boolean;
    canCreate: boolean;
    isProjectsLoaded: boolean;
    isError: boolean;
    updateProjectsList: (projects: ProjectDto[] | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectListView: React.FC<ProjectListViewProps> = ({
    projects, canDelete, canEdit, canCreate, updateProjectsList, isProjectsLoaded, isError,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const feedback = useFeedback();
    const { actions } = useContext(ProjectsContext);
    const [ open, setOpen ] = React.useState(false);
    const [ activeProjectId, setActiveProjectId ] = React.useState('');

    const menuItems: ContextMenuItem[] = [];

    const handleNewProjectClick = useCallback((): void => {
        history.push(RoutePath.CREATE_PROJECT);
    }, []);

    if (canEdit) {
        menuItems.push({
            text: 'project_list_context_menu_project_edit',
            testId: 'edit-project-button',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                history.push(generatePath(RoutePath.EDIT_PROJECT, { projectName: data.name }));
            },
        });
    }

    if (canDelete) {
        menuItems.push({
            text: 'project_list_context_menu_project_delete',
            testId: 'delete-project-button',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, data: any): void => {
                setOpen(true);
                setActiveProjectId(data.id);
            },
        });
    }

    const deleteSelectedProject = (): void => {
        deleteProject(activeProjectId).then(() => {
            closeDialog();
            /* Update state so that page can load faster with new list */
            const index = projects?.findIndex(project => project.id === activeProjectId);
            if (typeof index !== 'undefined' && index !== -1 && projects) {
                actions.updateProjectsList(projects[index], UpdateType.Delete);
                updateProjectsList(projects?.filter(project => project.id !== activeProjectId));
            }
            feedback.enqueueSuccess(t('feedback_delete_success_project'));
            return true;
        })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'Project Details',
                    message: 'Error while deleting project',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('error_delete_project_unkonwn_error'),
                    {
                        20203: { 0: activeProjectId || '' },
                        20209: { 0: activeProjectId || '' },
                        20204: { 0: activeProjectId || '' },
                        20208: { 0: activeProjectId || '' },
                    },
                ));
            });
    };

    const closeDialog = (): void => {
        setOpen(false);
    };

    return <>

        {projects ? (projects.length > 0 ?
            <>
                <HeaderContainer>
                    <ProjectsLabel>
                        {t('projects')}
                    </ProjectsLabel>
                    {
                        canCreate ?
                            <CreateProjectButtonContainer>
                                <CreateProjectButton
                                    id="create-project-button"
                                    disableElevation
                                    disableFocusRipple
                                    color="primary"
                                    onClick={handleNewProjectClick}
                                    data-cy="newProject"
                                    aria-label={t('create_project')}>
                                    {t('create_project')}
                                    &nbsp;
                                    <AddRoundedIcon />
                                </CreateProjectButton>
                            </CreateProjectButtonContainer> : null
                    }
                </HeaderContainer>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginLeft: '-24px',
                }}>
                    <CustomDialog
                        title={t('project_delete_dialog_title_text')}
                        open={open}
                        handleClose={closeDialog}
                        closeIconButton
                        infoText={t('project_delete_dialog_info_text')}
                        warningText={t('project_delete_dialog_warning_text')}
                        confirmationText={t('project_delete_dialog_confirmation_text')}
                        primaryButtonText={t('dialog_button_confirm_text')}
                        secondaryButtonText={t('dialog_button_cancel_text')}
                        primarybuttonCallback={deleteSelectedProject}
                        secondarybuttonCallback={closeDialog} />

                    {projects.map((project: ProjectDto, index: number) =>
                        <div
                            data-cy={`project_${project.name!}`}
                            key={index}>
                            <ProjectCard
                                // todo, chibicha: update type generation to create non null properties and ask backend team to make sure swagger is generated properly.
                                title={project.name!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                description={project.description!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                leftBadgeValue={project.activePipelines!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                rightBadgeValue={project.deployedPackages!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
                                leftBadgeTitle={t('project_list_active_pipelines')}
                                rightBadgeTitle={t('project_list_deployed_packages')}
                                route={generatePath(RoutePath.PROJECT_DASHBOARD, { projectName: project?.name })}
                                projectId={project.id || ''}
                                projectName={project.name || ''}
                                contextMenuItems={menuItems} />
                        </div>,
                    )}
                </div>
            </> :
            (isProjectsLoaded ?
                (<PortalLanding>
                    {canCreate && (
                        <LandingButton
                            id="create-project-button"
                            data-cy="landing-create"
                            variant="contained"
                            color="secondary"
                            disableElevation
                            name="create project"
                            aria-label={t('a11y_create_project')}
                            size='large'
                            startIcon={<AddIcon />}
                            onClick={(): void => {
                                history.push(RoutePath.CREATE_PROJECT);
                            }}
                        >
                            {t('create_project')}
                        </LandingButton>)}
                </PortalLanding>
                ) : (!isError ? <FullPageLoader
                    open
                    transparent /> : null))
        ) : <h2>
            {t('projects')}
        </h2>}
    </>;
};

interface PortalLandingProps {
    children: ReactNode;
}

const PortalLanding: React.FC<PortalLandingProps> = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className="portal-landing">
            <div className="text-area">
                <div
                    className="title"
                    title="">
                    {t('project_list_landing_title')}
                </div>
                <div className="sub-title">
                    {t('project_list_landing_subtitle')}
                </div>
                <div className="middle-slots">
                    {children}
                </div>
                <div className="footer-slots" />
            </div>

            <div className="image-spacing" />
            <img
                className="image"
                alt="landing-image"
                src={landingImage} />
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default ProjectList;
